/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/4/2021
 * @Example
 */

import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F3010_actions";
import {delay} from "redux-saga";

export default function W09F3010Sagas () {
    return [
        watchGetMembers(),
        watchGetForm(),
        watchSelectedEmployees(),
        watchLeaveGroup(),
        watchUpdateRole(),
        watchUpdatePicGroup(),
    ];
}

export function* getForm (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3010/load-master", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F3010_GET_FORM_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error", e);
    }
}

export function* watchGetForm () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get members
export function* getMembers (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3010/load-member", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F3010_LOAD_MEMBERS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3010 get members error");
    }
}

export function* watchGetMembers () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_LOAD_MEMBERS, getMembers);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//selected employees
export function* selectedEmployees (data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f3010/add-member", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3010 selected employees error");
    }
}

export function* watchSelectedEmployees () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_SELECTED_EMPLOYEES, selectedEmployees);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//leave group
export function* leaveGroup (data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f3010/leave-group", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3010 leave group error");
    }
}

export function* watchLeaveGroup () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_LEAVE_GROUP, leaveGroup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//update role
export function* updateRole (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3010/update-role-administrator", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3010 update role error");
    }
}

export function* watchUpdateRole () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_UPDATE_ROLE, updateRole);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//update picture group
export function* updatePicGroup (data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f3010/update-pic-group", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3010 update pic group error");
    }
}

export function* watchUpdatePicGroup () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3010_UPDATE_PIC_GROUP, updatePicGroup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

