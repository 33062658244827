/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

export const types = {
    W09F2200_GET_CBO_ADDRESS  : "W09F2200_GET_CBO_ADDRESS",
    W09F2200_GET_LIST_RELATIVE: "W09F2200_GET_LIST_RELATIVE",
    W09F2200_GET_FORM         : "W09F2200_GET_FORM",
    W09F2200_SAVE           : "W09F2200_SAVE",
    W09F2200_DELETE           : "W09F2200_DELETE",
};

export function getListRelative(params, cb) {
    return {
        type: types.W09F2200_GET_LIST_RELATIVE,
        cb,
        params
    };
}

export function getCboAddress(params, cb) {
    return {
        type: types.W09F2200_GET_CBO_ADDRESS,
        cb,
        params
    };
}

export function getForm(params, cb) {
    return {
        type: types.W09F2200_GET_FORM,
        cb,
        params
    };
}

export function save(params, cb) {
    return {
        type: types.W09F2200_SAVE,
        cb,
        params
    };
}

export function deleteData(params, cb) {
    return {
        type: types.W09F2200_DELETE,
        cb,
        params
    };
}


