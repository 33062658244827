export const types = {
    W09F6000_GET_CBO_DEPARTMENT: 'W09F6000_GET_CBO_DEPARTMENT',
    W09F6000_GET_CBO_TEAM: 'W09F6000_GET_CBO_TEAM',
    W09F6000_GET_CBO_LABOROBJECT: 'W09F6000_GET_CBO_LABOROBJECT',
    W09F6000_GET_GRID_EMPLOYEES: 'W09F6000_GET_GRID_EMPLOYEES',
    W09F6000_SAVE_SELECTED_EMPLOYEES: 'W09F6000_SAVE_SELECTED_EMPLOYEES',
    W09F6000_GET_CBO_PROJECTS: 'W09F6000_GET_CBO_PROJECTS',
    W09F6000_GET_CBO_DIRECT_MANAGER: 'W09F6000_GET_CBO_DIRECT_MANAGER'
};

export function getCboProjects(params,cb) {
    return{
        type: types.W09F6000_GET_CBO_PROJECTS,
        params,
        cb
    }
}

export function getCboDirectManager(params,cb){
    return {
        type: types.W09F6000_GET_CBO_DIRECT_MANAGER,
        params,
        cb
    }
}

export function getCboLaborObject(params,cb) {
    return{
        type: types.W09F6000_GET_CBO_LABOROBJECT,
        params,
        cb
    }
}

export function getGridEmployees(params,cb) {
    return{
        type: types.W09F6000_GET_GRID_EMPLOYEES,
        params,
        cb
    }
}

export function saveSelectedEmployee(params,cb) {
    return{
        type: types.W09F6000_SAVE_SELECTED_EMPLOYEES,
        params,
        cb
    }
}


