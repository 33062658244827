/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 4/9/2020
 * @Example
 */

import { types } from "./W09F9000_actions";
const initialState = {
  getManualLeftPanel: [],
  getManualRightPanel: []
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F9000_LOAD_USER_MANUAL_LEFT_SUCCESS: {
      return { ...state, getManualLeftPanel: action.data };
    }
    case types.W09F9000_LOAD_USER_MANUAL_RIGHT_SUCCESS: {
      return { ...state, getManualRightPanel: action.data };
    }
    default:
      return state;
  }
}
