
import Api                                   from "../../../services/api";
import {cancel, take, takeEvery, takeLatest, put} from "redux-saga/effects";
import {types}                               from "./W09F4000_actions";
import {delay}                               from "redux-saga";

export default function W09F4000Sagas() {
    return [
        watchGetListEmployeeDepartment(),
        watchGetDataForm(),
        watchGetListCboPosition(),
    ];
}

export function* getListEmployeeDepartment(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f4000/load-employee-department", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get List Employee Department error", e);
    }
}

export function* watchGetListEmployeeDepartment() {
    while (true) {
        const watcher = yield takeLatest(types.W09F4000_GET_LIST_EMPLOYEE_DEPARTMENT, getListEmployeeDepartment);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDataForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f4000/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error", e);
    }
}

export function* watchGetDataForm() {
    while (true) {
        const watcher = yield takeEvery(types.W09F4000_LOAD_FORM, getDataForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getListCboPosition(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f4000/get-cbo-search", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F4000_GET_LIST_CBO_POSITION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get list cbo position", e);
    }
}

export function* watchGetListCboPosition() {
    while (true) {
        const watcher = yield takeEvery(types.W09F4000_GET_LIST_CBO_POSITION, getListCboPosition);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}



