/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example
 */

export const types = {
    W09F2225_GET_DATA_GRID: "W09F2225_GET_DATA_GRID",
    W09F2225_LOAD_CBO_PROPERTY: "W09F2225_LOAD_CBO_PROPERTY",
    W09F2225_LOAD_CBO_PROPERTY_SUCCESS: "W09F2225_LOAD_CBO_PROPERTY_SUCCESS",
    W09F2225_DETELE: "W09F2225_DETELE",

};


export function getDataGrid(params, cb) {
    return {
        type: types.W09F2225_GET_DATA_GRID,
        params,
        cb
    };
}

export function getDataCboProperty(params, cb) {
    return {
        type: types.W09F2225_LOAD_CBO_PROPERTY,
        params,
        cb
    };
}


export function deleteData(params, cb) {
    return {
        type: types.W09F2225_DETELE,
        params,
        cb
    };
}


