/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

import Api from "../../../services/api";
import {cancel, take, takeLatest, takeEvery} from "redux-saga/effects";
import {types} from "./W09F2200_actions";
import {delay} from "redux-saga";

export default function W09F2200Sagas() {
    return [watchGetForm(), watchGetCboAddress(), watchSave(), watchGetListRelative(), watchDeleteData()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2002/get-detail", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error ");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2200_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboAddress(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2205/load-cbo-address", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo address error");
    }
}

export function* watchGetCboAddress() {
    while (true) {
        const watcher = yield takeEvery(types.W09F2200_GET_CBO_ADDRESS, getCboAddress);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f2200/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2200_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getListRelative(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2210/load-list-relative", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get list relative error");
    }
}

export function* watchGetListRelative() {
    while (true) {
        const watcher = yield takeEvery(types.W09F2200_GET_LIST_RELATIVE, getListRelative);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f2210/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2200_DELETE, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

