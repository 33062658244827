/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 13/09/2021
 * @Example
 */

import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2281_actions";
import { delay } from "redux-saga";

export default function W09F2281Sagas() {
    return [
        watchGetFormPopup(),
        watchAdd(),
        watchEditRow(),
        watchGetGridEmployee(),
        watchLoadChooseEmployee(),
    ];
}

export function* getFormPopup(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2281/load-master", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetFormPopup() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2281_LOAD_FORM_POPUP, getFormPopup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getGridEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2281/load-gird", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetGridEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2281_LOAD_EMPLOYEE, getGridEmployee);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f2281/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2281_ADD, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f2281/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update error");
    }
}

export function* watchEditRow() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2281_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadChooseEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2281/load-data-w09f2281");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update error");
    }
}

export function* watchLoadChooseEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2281_LOAD_CHOOSE_EMPLOYEE, loadChooseEmployee);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
