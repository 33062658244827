/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 6/25/2021
 * @Example
 */
export const types = {
    W09F2230_GET_FORM: "W09F2230_GET_FORM",
    W09F2230_EDIT: "W09F2230_EDIT",
};

export function getForm(cb) {
    return {
        type: types.W09F2230_GET_FORM,
        cb,
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2230_EDIT,
        params,
        cb,
    };
}
