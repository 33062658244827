/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example 
 */

import Api                             from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./W09F2225_actions";
import {delay}                         from "redux-saga";

export default function W09F2225Sagas() {
    return [
        watchGetDataGrid(),
        watchDeleteData(),
        watchGetDataCbo(),

    ];
}

//get data grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2225/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2225_GET_DATA_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDataCboProperty(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2225/load-combo", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2225_LOAD_CBO_PROPERTY_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data cbo error");
    }
}

export function* watchGetDataCbo() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2225_LOAD_CBO_PROPERTY, getDataCboProperty);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//deleteData
export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f2225/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2225_DETELE, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

