/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example 
 */

import {types} from "./W09F2225_actions";

const initialState = {
    getDataCboProperty: []
};

export default function (state = initialState, action = {}) {
    if(action.type === types.W09F2225_LOAD_CBO_PROPERTY_SUCCESS)
    {
        return {
            ...state,
            getDataCboProperty: action.data
        }
    }
    return state
}
