/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../../services/api';
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W09F6000/W09F6000_actions";
import {delay} from "redux-saga";

export default function W09F6000Sagas() {
    return [
        watchGetCboLaborObject(),
        watchGetGridEmployees(),
        watchSaveSelectedEmployees(),
        watchGetCboProjects(),
        watchGetCboDirectManager()
    ];
}

//get cbo project..
export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-cbo-project', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error', e);
    }
}

export function* watchGetCboProjects() {
    while (true) {
        const watcher = yield takeLatest(types.W09F6000_GET_CBO_PROJECTS, getCboProjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo labor object..
export function* getCboLaborObject(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f6000/get-labor-object', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo labor object error');
    }

}

export function* watchGetCboLaborObject() {
    while (true){
        const watcher = yield takeLatest(types.W09F6000_GET_CBO_LABOROBJECT, getCboLaborObject);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get grid employees..
export function* getGridEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f6000/load-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid employees error', e);
    }

}

export function* watchGetGridEmployees() {
    while (true){
        const watcher = yield takeLatest(types.W09F6000_GET_GRID_EMPLOYEES, getGridEmployees);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//save selected employees..
export function* saevSelectedEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f6000/chosen', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save selected employees error');
    }

}

export function* watchSaveSelectedEmployees() {
    while (true){
        const watcher = yield takeLatest(types.W09F6000_SAVE_SELECTED_EMPLOYEES, saevSelectedEmployees);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboDirectManager(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f6000/load-cbo-direct-manager', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo direct manager error', e);
    }
}

export function* watchGetCboDirectManager() {
    while (true) {
        const watcher = yield takeLatest(types.W09F6000_GET_CBO_DIRECT_MANAGER, getCboDirectManager);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
