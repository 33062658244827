/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import {types} from "./W09F3000_actions";

const initialState = {
    getGrid: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F3000_LOAD_GRID_SUCCESS: {
            return {
                ...state,
                getGrid: action.data
            };
        }
        default:
            return state;
    }
}
