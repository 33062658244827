/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 6/25/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W09F2230_actions";

export default function W09F2230Sagas() {
    return [watchGetForm(), watchEdit()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2330/get-form");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2230_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2330/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit form error");
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2230_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
