/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/4/2021
 * @Example
 */

import {types} from "./W09F3010_actions";

const initialState = {
    getForm:    null,
    getMembers: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F3010_LOAD_MEMBERS_SUCCESS: {
            return {
                ...state,
                getMembers: action.data
            };
        }
        case types.W09F3010_GET_FORM_SUCCESS: {
            return {
                ...state,
                getForm: action.data
            };
        }
        default:
            return state;
    }
}
